import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DoubleRangeNumberFilterParams } from '@app/shared/models/filters.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-range-number-filter',
  templateUrl: './range-number-filter.component.html',
  styleUrls: ['./range-number-filter.component.scss'],
})
export class RangeNumberFilterComponent implements OnInit, OnChanges {
  localValue: DoubleRangeNumberFilterParams;
  form: FormGroup;

  @Input() label: string;
  @Input() maxValue: number = null;

  @Output() submitEvent = new EventEmitter<DoubleRangeNumberFilterParams>();

  @Input() set value(value: string[]) {
    if (!!value?.length) {
      this.form.setValue({
        from: value[0] ? value[0] : 0,
        to: value[1] ? value[1] : null,
      });
    }
  }

  get isActiveButton() {
    return (this.form.get('from').value || this.form.get('to').value) && this.isMaxValueValidator;
  }

  get isMaxValueValidator() {
    return !!this.maxValue ? !this.form.get('to').invalid : true;
  }

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      from: [null],
      to: [null],
    });
  }

  ngOnInit() {
    this.localValue = Object.assign({}, this.localValue);

    if (!!this.maxValue) {
      this.form.get('to').addValidators([Validators.max(this.maxValue)]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    const { value } = changes;

    if (value && value.currentValue) {
      this.localValue = Object.assign({}, this.localValue);
    }
  }

  apply() {
    this.submitEvent.emit({
      from: this.form.get('from').value,
      to: this.form.get('to').value,
    });
  }
}
