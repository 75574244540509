<app-rich-modal (close)="onChatClose()" class="chat">
  <div class="chat__loader" *ngIf="chatLoading$ | async">
    <app-svg-icon [height]="64" [width]="64" name="loader-animate" class="chat__loader-icon"></app-svg-icon>
  </div>
  <div class="top-icon">
    <!-- иконки перемещения и закрепления раскоментить\заменить при реализации функционала -->
    <!-- <button class="icon-button">
      <app-svg-icon name="move" width="16" height="16"></app-svg-icon>
    </button>
    <button class="icon-button">
      <app-svg-icon name="pin" width="16" height="16"></app-svg-icon>
    </button> -->
  </div>
  <div class="content">
    <div class="left" [style.width]="isContactsHidden ? '0px' : ''">
      <div [ngClass]="isContactsHidden ? 'hidden' : ''">
        <app-chat-section-select></app-chat-section-select>
      </div>
      <div class="filter">
        <div
          [ngClass]="isContactsHidden ? 'hidden' : ''"
          *ngIf="
            chatSectionSelected.name !== chatSectionsEnum.TECH && chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO
          "
        >
          <app-chat-contacts-filter></app-chat-contacts-filter>
        </div>
      </div>
      <div class="groups">
        <div [ngClass]="isContactsHidden ? 'hidden' : ''">
          <app-chat-groups></app-chat-groups>
          <app-chat-contacts
            *ngIf="
              chatSectionSelected.name !== chatSectionsEnum.TECH &&
              chatSectionSelected.name !== chatSectionsEnum.DUTY_TSO
            "
          ></app-chat-contacts>
        </div>
      </div>
    </div>
    <div class="center">
      <router-outlet></router-outlet>

      <button
        class="icon-button"
        [class]="!isContactsHidden ? 'icon-button-left' : ''"
        (click)="$event.stopPropagation(); toggleContactsHidden()"
      >
        <app-svg-icon [name]="isContactsHidden ? 'right' : 'left'" width="6" height="10"></app-svg-icon>
      </button>
    </div>
    <div class="right">
      <app-chat-tabs></app-chat-tabs>
      <div class="chat-messages">
        <router-outlet></router-outlet>
      </div>
      <div>
        <app-chat-input *ngIf="!isTradeGroup(contactSelected)"></app-chat-input>
      </div>
    </div>
  </div>
</app-rich-modal>
